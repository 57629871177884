import { render, staticRenderFns } from "./AppLayoutDefault.vue?vue&type=template&id=45f09138&scoped=true"
import script from "./AppLayoutDefault.vue?vue&type=script&lang=js"
export * from "./AppLayoutDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f09138",
  null
  
)

export default component.exports