<template>
  <v-app>
    <slot />
  </v-app>
</template>

<script>
import ButtonDark from "../components/ButtonDark";
export default {
  name: "AppLayoutDefault",
  components:{
    ButtonDark
  }
}
</script>

<style scoped>

</style>
